import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 抹平各个浏览器的差异
import 'normalize.css'
// 引入基础样式
import './style/index.scss'

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

createApp(App).use(store).use(router).mount('#app')
